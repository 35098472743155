import React, { useState } from "react"
import PageRoot from "../components/PageRoot"
import { gql, useQuery } from "@apollo/client"
import LoadingScreen from "../components/LoadingScreen"
import ErrorScreen from "../components/ErrorScreen"
import { Empty, Row, Space } from "antd"
import EventureCarousel from "../components/EventureCarousel"
import ContainerWithPadding from "../components/ContainerWithPadding"
import UserPurchasedItems from "../components/UserPurchasedItems"
import { renderCarouselItem } from "../utils"
import EventureGrid from "../components/EventureGrid"
import EventureCard from "../components/EventureCard"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import CouponPreview from "../components/coupon"
import { StaticImage } from "gatsby-plugin-image"
import { useGlobalValue } from "../hooks/useGlobalValue"

const GET_USER_DATA_QUERY = gql`
  query libraryData($now: timestamptz, $userId: String) {
    coupon_codes(
      where: {
        criterion: {
          _and: [
            {
              _or: [
                { start_at: { _lte: $now } }
                { start_at: { _is_null: true } }
              ]
            }
            { _or: [{ end_at: { _gt: $now } }, { end_at: { _is_null: true } }] }
          ]
        }
        consumed_at: { _is_null: true }
        user_id: { _eq: $userId }
      }
      order_by: { criterion: { end_at: asc_nulls_last } }
    ) {
      code
      user_id
      criterion {
        description
        discount_type
        end_at
        exclusive_for_content_id
        exclusive_for_content_type
        id
        percentage
        picture {
          s3_key
        }
        short_description
        start_at
        subtitle
        title
        value
      }
    }
    user_video_histories(
      order_by: { updated_at: desc_nulls_last }
      limit: 10
      distinct_on: updated_at
    ) {
      time
      video {
        __typename
        price_policy
        id
        state
        title
        subtitle
        level
        short_description
        duration
        seo {
          slug
        }
        course_rel {
          course_id
        }
        landscape {
          s3_key
        }
      }
    }
    favorite_courses: user_favorites(
      where: { item_type: { _eq: "COURSE" }, is_favorite: { _eq: true } }
      limit: 10
    ) {
      course {
        __typename
        id
        price_policy
        title
        subtitle
        level
        short_description
        seo {
          slug
        }
        landscape {
          s3_key
        }
        videos {
          video {
            state
            duration
          }
        }
      }
    }
    favorite_authors: user_favorites(
      where: { item_type: { _eq: "AUTHOR" }, is_favorite: { _eq: true } }
      limit: 10
    ) {
      author {
        __typename
        id
        firstname
        lastname
        title
        seo {
          slug
        }
        picture {
          s3_key
        }
        video_rels {
          video_id
        }
      }
    }
  }
`

const MyLibraryPage = () => {
  const [{ user, isLoadingUser }] = useGlobalValue()
  const { t } = useTranslation()
  const [now, setNow] = useState(new Date())

  const { loading, error, data } = useQuery(GET_USER_DATA_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      userId: user?.user_id,
      now,
    },
  })

  const pageTitle = t("label:library")

  if (loading || isLoadingUser) {
    return (
      <PageRoot title={pageTitle} showTitle>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot title={pageTitle} showTitle>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const favoriteCourses = data.favorite_courses.map(item => item.course)
  const favoriteAuthors = data.favorite_authors.map(item => item.author)

  const historyVideos = data.user_video_histories.map(item => {
    const { time, video } = item
    let progress = 0
    try {
      progress = (time / video.duration) * 100
    } catch (err) {
      console.log({ progressCalcError: err })
    }
    return {
      ...video,
      progress: progress,
    }
  })

  const headerItems = [
    {
      title: t("label:anatomicalAtlas"),
      picture: (
        <StaticImage
          placeholder="blurred"
          src={"../../static/assets/human/cover-bio.jpg"}
          alt={t("label:anatomicalAtlas")}
        />
      ),
      url: "/human",
    },
    {
      title: t("label:myArticles"),
      picture: (
        <StaticImage
          placeholder="blurred"
          src={"../../static/assets/human/articoli-cover.jpg"}
          alt={t("label:anatomicalAtlas")}
        />
      ),
      url: "",
      disabled: true,
    },
    {
      title: t("label:myCertifications"),
      picture: (
        <StaticImage
          placeholder="blurred"
          src={"../../static/assets/images/le-mie-certificazioni.jpg"}
          alt={t("label:anatomicalAtlas")}
        />
      ),
      url: "/me/online-tests",
    },
  ]

  const promotionItems = [
    {
      // title: t("label:specialPrices"),
      picture: (
        <StaticImage
          placeholder="blurred"
          src={"../../static/assets/images/time-special-offer.jpg"}
          alt={t("label:specialPrices")}
        />
      ),
      url: "/special-price",
    },
    {
      // title: t("label:giftCards"),
      picture: (
        <StaticImage
          placeholder="blurred"
          src={"../../static/assets/images/gift-card-sport-science.jpg"}
          alt={t("label:giftCards")}
        />
      ),
      url: "/gift-card",
    },
    {
      // title: t("label:bundles"),
      picture: (
        <StaticImage
          placeholder="blurred"
          src={"../../static/assets/images/bundles-sport-science.jpg"}
          alt={t("label:bundles")}
        />
      ),
      url: "/bundle",
    },
  ]

  return (
    <PageRoot title={pageTitle} showTitle>
      <ContainerWithPadding>
        {/*<EventureGrid*/}
        {/*  colSettings={{ sx: 24, sm: 24, md: 24, lg: 8 }}*/}
        {/*  dataSource={[...headerItems]}*/}
        {/*  renderItem={({ title, picture, url, disabled }, index) => {*/}
        {/*    return (*/}
        {/*      <EventureCard*/}
        {/*        key={index}*/}
        {/*        title={title}*/}
        {/*        disabled={disabled}*/}
        {/*        type="full"*/}
        {/*        cover={picture}*/}
        {/*        onClick={() => {*/}
        {/*          navigate(url)*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    )*/}
        {/*  }}*/}
        {/*/>*/}

        {/*<EventureGrid*/}
        {/*  title={t("label:promotionsForYou")}*/}
        {/*  colSettings={{ sx: 24, sm: 24, md: 24, lg: 8 }}*/}
        {/*  dataSource={[...promotionItems]}*/}
        {/*  renderItem={({ title, picture, url, disabled }, index) => {*/}
        {/*    return (*/}
        {/*      <EventureCard*/}
        {/*        key={index}*/}
        {/*        title={title}*/}
        {/*        disabled={disabled}*/}
        {/*        type="full"*/}
        {/*        cover={picture}*/}
        {/*        onClick={() => {*/}
        {/*          navigate(url)*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    )*/}
        {/*  }}*/}
        {/*/>*/}
      </ContainerWithPadding>

      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        {/*<Row>*/}
        {/*  <EventureCarousel*/}
        {/*    title={t("label:myCoupons")}*/}
        {/*    dataSource={data.coupon_codes}*/}
        {/*    emptyItem={<Empty description={t("label:noCouponsAvailable")} />}*/}
        {/*    renderItem={(item, index) => {*/}
        {/*      return <CouponPreview key={index} data={item} />*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Row>*/}
        <UserPurchasedItems />
        <Row>
          <EventureCarousel
            title={t("label:chronology")}
            dataSource={historyVideos}
            renderItem={renderCarouselItem}
            emptyItem={<Empty description={t("message:noVideoWatched")} />}
          />
        </Row>
        <Row>
          <EventureCarousel
            colSettings={{ xs: 10, sm: 10, md: 8, lg: 6, xl: 4, xxl: 3 }}
            title={t("label:followingAuthors")}
            dataSource={favoriteAuthors}
            renderItem={renderCarouselItem}
            emptyItem={<Empty description={t("message:noAuthorFollowing")} />}
          />
        </Row>
        <Row>
          <EventureCarousel
            title={t("label:myFavorites")}
            dataSource={favoriteCourses}
            renderItem={renderCarouselItem}
            emptyItem={<Empty description={t("message:noFavorites")} />}
          />
        </Row>
      </Space>
    </PageRoot>
  )
}

export default MyLibraryPage
